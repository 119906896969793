* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.dashboard-page {
  min-height: 100vh;
  width: 100% !important;
}

.home-container-page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-first-pages {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #e0ffff;
  flex-direction: row;
}

.home-img-content-box {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imge-circle-view {
  position: relative;
  bottom: 15px;
  width: 22%;
}

.content-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  left: 30px;
}

.home-page-text {
  font-size: 58.24px;
  font-weight: 300;
  color: #556b2f;
  font-family: "Merriweather";
  text-align: "center";
  text-transform: capitalize;
}

.home-heading-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding-bottom: 15px;
}

.img-heading-home {
  position: relative;
  bottom: 24px;
}

.home-heading-para {
  color: #004D4D;
  font-family: "Josefin Sans";
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  text-transform: capitalize;
}

.home-heading-para-ans {
  font-weight: 400;
  font-family: "Josefin Sans";
  font-size: 24px;
  text-align: center;
  color: #004D4D;
}

.home-center-line {
  width: 60%;
  height: 1px;
  background-color: #008D8D;
  border: none;
}

.testimonial-card-title-line {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}


.testimonial-center-line {
  width: 70%;
  height: 1px;
  text-align: center;
  background-color: #008D8D;
  border: none;
}

.home-page-para-text {
  color: #000606;
  font-size: 18px;
  font-family: "Roboto";
  text-align: "center";
  text-transform: capitalize;
}

.home-imgs-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 23%;
}

.imge-circle-view-row {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: 25px;
  justify-content: flex-end;
}

.circle-line-img-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 20px;
}

.home-aboutUs-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}

.sub-aboutUs-container {
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
}

.aboutUS-view-row {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.aboutUs-contant-home-page {
  width: 50%;
  padding-left: 30px;
}

.content-aboutUs-text-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
  height: 45vh;
}

.aboutUs-text-heading {
  color: #556b2f;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Merriweather";
}

.aboutUs-text-para-heading {
  color: #000606;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
}

.aboutUs-text-paras {
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto";
  color: #707373;
  text-align: justify;
}

.home-works-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 20px;
  background-image: url("../assets/images/itworks.png");
  background-size: cover;
  padding: 20px;
  width: 100%;
}

.sub-works-container {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.step-tracker {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}

.step {
  display: flex;
  align-items: center;
  margin: 8px 0;
  gap: 10px;
}

.para-step {
  color: #000606;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  text-transform: capitalize;
}

.work-heading-label {
  color: #556b2f;
  font-size: 24px;
  font-weight: 400;
  font-family: "Merriweather";
  text-transform: capitalize;
}

.home-books-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}

.sub-books-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.books-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
}

.books-img-view {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  overflow: hidden;
  white-space: nowrap;
}

.book-image {
  width: 397px;
  height: 516px;
  margin-right: 10px;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100%;
}

.dot {
  width: 15px;
  background-color: #ccc;
  border-radius: 8px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.image-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.dot.active {
  border-radius: 8px;
  width: 40px;
  height: 10px;
  background-color: #008080;
}

.book-para-text {
  color: #707373;
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto";
  text-align: Center;
}

.heading-books-Page {
  color: #556b2f;
  font-family: "Merriweather";
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.subHeading-books-Page {
  color: #000606;
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.book-image {
  width: 397px;
  height: 516px;
  margin-right: 20px;
}

.home-faq-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  background-image: url("../assets/images/FAQSBackground.png");
  background-size: cover;
  padding: 20px;
  width: 100%;
}

.sub-faq-container {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.faq-content-container {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;
  padding-top: 120px;
}

.faq-form-container {
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 50%;
}

.faq-heading-text {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 38px;
  color: #000606;
}

.faq-form-box-container {
  margin-top: 30px;
  max-height: 270px;
  overflow-y: auto;
}

.faq-heading-para {
  color: #000606;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.collapsible {
  margin-top: 20px;
}

.collapsible-button {
  width: 80%;
  border: none;
  padding: 10px 20px;
  gap: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.collapsible-button.open {
  background-color: #008080;
  color: white;
}

.collapsible-button.closed {
  background-color: #ffffff;
  color: #000000;
}

.collapsible-content {
  padding: 20px;
  width: 80%;
  background-color: #ffffff;
}

.collapsible-heading {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.ollapsible-para {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  color: #000606;
}

.home-story-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-image: url("../assets/images/childImg.jpg");
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 50px;
  width: 100%;
  height: 465.5px;
}

.sub-story-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.story-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
}

.story-heading-color {
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}

.story-heading--para {
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.home-Testimonial-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
}

.sub-Testimonial-container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.Testimonial-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
}

.testimonial-card-title-text {
  color: #009A9A;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  font-family: "Roboto";
}

.testimonial-card-para-text {
  color: #000606;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto";
}

.testimonial-card-study-text {
  color: #707373;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: "Roboto";
}

.testimonial-para-text {
  color: #000606;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins";
}

.testimonial-card-textPlaceholder-text {
  color: #000606;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  font-family: "Poppins";
}

.testimonial-heading-text {
  color: #556b2f;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  font-family: "Merriweather";
}

.card-view-profile-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.testimonial-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background: white;
  width: 450px;
  height: 350px;
  padding: 8px;
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
}

.pagination button {
  background: #008080;
  color: white;
  border: 1px solid #008080;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
}

.books-img-view {
  position: relative;
}

.arrow-icons {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.arrow-icons {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  width: 100%;
}

.arrow-icon {
  cursor: pointer;
  background-color: #008080;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.arrow-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.pagination button:disabled {
  background-color: transparent;
  border: 1px solid #008080;
  color: #008080;
  cursor: not-allowed;
}

.image-slider-mobile {
  display: none;
}

.testimonial-card-heading-text {
  color: #000606;
  font-family: "Roboto";
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.testimonial-card-para-text {
  color: #000606;
  font-family: "Roboto";
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.second-heading-text-home {
  position: relative;
  bottom: 15px;
  font-size: 58.24px;
  font-weight: 700;
  color: #008080;
  font-family: "Merriweather";
  text-align: center;
}

.img-childern-one-homePage {
  width: 230px;
  height: 460px;
}

.video-size-align {
  width: 620px;
  height: 380px;
}

.text-btn-content-mobile {
  display: none;
}

.img-home-page-circle {
  width: 80px;
  height: 110px;
}

.img-children-homePage-two {
  width: 320px;
  height: 448px;
}

.aboutUs-img-first {
  width: 570px;
  height: 490px;
}

.custom-button {
  width: 214px;
  height: 56px;
  background-color: #008080;
  color: #ffffff;
  font-family: "Poppins";
  border: none;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.custom-button-disabled {
  background-color: #008080;
  color: #dcdcdc;
  cursor: pointer;
  font-family: "Poppins";
  border: none;
  width: 214px;
  height: 56px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}

.custom-button:hover {
  background-color: #006666;
}

.custom-button-aboutUs {
  width: 180px;
  height: 56px;
  border-radius: 8px;
  background-color: #008080;
  color: #ffffff;
  font-family: "Poppins";
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.custom-button-FAQ {
  width: 180px;
  height: 56px;
  border-radius: 8px;
  background-color: #008080;
  color: #ffffff;
  font-family: "Poppins";
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.page-book-img {
  width: 80px;
  height: 80px;
}

.check-img {
  width: 16px;
  height: 16px;
}

.about-img {
  width: 636px;
  height: 670px;
}

.para-works-page-text {
  font-family: "Poppins";
  color: #000606;
  font-size: 40px;
  font-weight: 600;
  text-transform: "capitalize";
}

.img-faq-pages {
  width: 474px;
  height: 474px;
}

.arrow-faq-img-page {
  width: 24px;
  height: 24px;
}

.collapsible-text-heading {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500;
}

.icon-img-testimonial-page {
  width: 34px;
  height: 34px;
}

.video-react {
  border-radius: 10px !important;
  background-color: transparent !important;
}

.video-react .video-react-poster {
  border-radius: 10px !important;
}

.video-react .video-react-video {
  border-radius: 10px !important;
}

.custom-btn-align-works {
  margin-top: 50px;
}

.books-align-content-text-card {
  width: 50%;
  margin-top: 0px;
}

.video-container {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/images/video-background-image.png');
  background-size: cover;
  height: 70vh !important;
}

.video-close-icon {
  position: absolute;
  top: 4%;
  left: 83%;
  background: transparent !important;
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.second-heading-aboutUS-para {
  width: 80%;
  margin-top: 10px;
}

.heading-aboutUS-paras-text {
  width: 80%;
}

.first-heading-aboutUS-para {
  width: 80%;
  margin-bottom: 15px;
}

.btn-aboutUs-align {
  width: 80%;
  margin-top: 10px;
}

.btn-FAQ-align {
  width: 80%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 30px;
}

.card-view-profile-container-mobile {
  display: none;
}

.home-first-pages-mobile {
  display: none;
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .home-first-pages {
    padding-top: 25px;
  }
}

@media (min-width: 1025px) and (max-width: 1320px) {
  .video-container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/images/video-background-image.png');
    background-size: cover;
    height: 90vh !important;
  }

  .testimonial-card {
    width: 380px;
    height: 380px;
  }
}

@media only screen and (max-width: 991px) {
  .home-first-pages {
    height: 100%;
    width: 100%;
    padding-top: 40px;
  }


  .image-slider-mobile {
    display: none;
  }

  .text-btn-content-mobile {
    display: none;
  }

  .video-close-icon {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }

  .home-page-para-text {
    color: #000606;
    font-size: 16px;
    font-family: "Roboto";
    text-transform: capitalize;
    text-align: center;
  }

  .img-children-homePage-two {
    width: 200px;
    height: 250px;
  }

  .img-home-page-circle {
    width: 40px;
    height: 60px;
  }

  .text-btn-content-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/images/video-background-image.png');
    background-size: cover;
    height: 40vh !important;
  }

  .home-page-text {
    font-size: 24px;
  }

  .video-size-align {
    width: 220px;
    height: 180px;
  }

  .img-childern-one-homePage {
    width: 174px;
    height: 265px;
  }

  .custom-button {
    width: 140px;
    height: 40px;
    font-size: 12px;
  }

  .custom-button-aboutUs {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }

  .custom-button-FAQ {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }

  .second-heading-text-home {
    font-size: 20px;
  }

  .aboutUs-text-heading {
    font-size: 20px;
  }

  .aboutUs-text-paras {
    font-size: 14px;
    line-height: 20px;
  }

  .aboutUs-text-para-heading {
    font-size: 20px;
  }

  .aboutUs-img-first {
    width: 300px;
    height: 270px;
  }

  .page-book-img {
    width: 40px;
    height: 40px;
  }

  .check-img {
    width: 10px;
    height: 10px;
  }

  .about-img {
    width: 336px;
    height: 370px;
  }

  .work-heading-label {
    color: #556b2f;
    font-size: 16px;
  }

  .para-works-page-text {
    color: #000606;
    font-size: 20px;
  }

  .para-step {
    font-size: 14px;
  }

  .heading-books-Page {
    font-size: 20px;
  }

  .subHeading-books-Page {
    font-size: 25px;
  }

  .book-para-text {
    font-size: 14px;
    line-height: 20px;
  }

  .book-image {
    width: 230px;
    height: 300px;
    margin-right: 10px;
  }

  .dot.active {
    border-radius: 4px;
    width: 20px;
    height: 8px;
  }

  .img-faq-pages {
    width: 350px;
    height: 350px;
  }

  .faq-content-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 40%;
    padding-bottom: 50px;
  }

  .faq-heading-text {
    font-size: 25px;
  }

  .faq-heading-para {
    font-size: 13px;
  }

  .arrow-faq-img-page {
    width: 20px;
    height: 20px;
  }

  .collapsible-text-heading {
    font-size: 13px;
    text-align: left;
  }

  .collapsible-button {
    margin-left: 20px;
    width: 80%;
  }

  .home-story-container {
    height: 300px;
  }

  .story-heading-color {
    font-size: 25px;
  }

  .story-heading--para {
    font-size: 16px;
  }

  .testimonial-heading-text {
    font-size: 20px;
  }

  .testimonial-para-text {
    font-size: 30px;
  }

  .testimonial-card-para-text {
    font-size: 14px;
    line-height: 18px;
  }

  .icon-img-testimonial-page {
    width: 20px;
    height: 20px;
  }

  .css-1pqm26d-MuiAvatar-img {
    width: 70%;
    height: 70%;
  }

  .testimonial-card-heading-text {
    font-size: 16px;
  }

  .pagination button {
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 40%;
    margin: 0 2px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .video-close-icon {
    position: absolute;
    top: 5%;
    left: 60%;
  }

  .image-slider-mobile {
    display: none;
  }

  .custom-button {
    width: 214px;
    height: 56px;
    font-size: 16px;
  }

  .text-btn-content-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .home-first-pages {
    height: 100%;
    width: 100%;
    padding-top: 40px;
  }

  .aboutUS-view-row {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-slider-mobile {
    display: none;
  }

  .testimonial-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background: white;
    width: 290px;
    height: 380px;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .text-btn-content-mobile {
    display: none;
  }

  .video-react {
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .video-close-icon {
    top: 3%;
    left: 88%;
  }

  .video-react .video-react-video {
    border-radius: 10px;
  }

  .home-page-para-text {
    color: #000606;
    font-size: 16px;
    font-family: "Roboto";
    text-transform: capitalize;
    text-align: center;
  }

  .img-children-homePage-two {
    width: 180px;
    height: 230px;
  }

  .imge-circle-view {
    width: 15%;
  }

  .img-home-page-circle {
    width: 40px;
    height: 60px;
  }

  .home-page-text {
    font-size: 24px;
  }

  .video-size-align {
    width: 220px;
    height: 180px;
  }

  .img-childern-one-homePage {
    width: 130px;
    height: 290px;
  }

  .custom-button {
    width: 214px;
    height: 56px;
    font-size: 16px;
  }

  .custom-button-aboutUs {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }

  .custom-button-FAQ {
    width: 100px;
    height: 40px;
    font-size: 12px;
  }

  .second-heading-text-home {
    position: relative;
    bottom: 10px;
    font-size: 20px;
  }

  .aboutUs-text-heading {
    font-size: 20px;
  }

  .aboutUs-text-paras {
    font-size: 14px;
    line-height: 20px;
  }

  .aboutUs-text-para-heading {
    font-size: 20px;
  }

  .aboutUs-img-first {
    width: 300px;
    height: 270px;
  }

  .page-book-img {
    width: 60px;
    height: 60px;
  }

  .check-img {
    width: 10px;
    height: 10px;
  }

  .about-img {
    width: 310px;
    height: 400px;
  }

  .work-heading-label {
    color: #556b2f;
    font-size: 18px;
  }

  .para-works-page-text {
    color: #000606;
    font-size: 24px;
  }

  .para-step {
    font-size: 16px;
  }

  .heading-books-Page {
    font-size: 20px;
  }

  .subHeading-books-Page {
    font-size: 25px;
  }

  .book-para-text {
    font-size: 16px;
    line-height: 20px;
  }

  .collapsible-content {
    padding: 20px;
    width: 80%;
    background-color: #ffffff;
    margin-left: 20px;
  }

  .book-image {
    width: 280px;
    height: 340px;
    margin-right: 10px;
  }

  .dot.active {
    border-radius: 4px;
    width: 20px;
    height: 8px;
  }

  .img-faq-pages {
    width: 350px;
    height: 350px;
  }

  .faq-content-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 40%;
    padding-bottom: 50px;
  }

  .faq-heading-text {
    font-size: 25px;
  }

  .faq-heading-para {
    font-size: 16px;
  }

  .arrow-faq-img-page {
    width: 20px;
    height: 20px;
  }

  .collapsible-text-heading {
    font-size: 13px;
    text-align: left;
  }

  .collapsible-button {
    margin-left: 20px;
    width: 80%;
  }

  .home-story-container {
    height: 300px;
  }

  .story-heading-color {
    font-size: 25px;
  }

  .story-heading--para {
    font-size: 16px;
  }

  .testimonial-heading-text {
    font-size: 20px;
  }

  .testimonial-para-text {
    font-size: 30px;
  }

  .testimonial-card-para-text {
    font-size: 14px;
    line-height: 18px;
  }

  .icon-img-testimonial-page {
    width: 20px;
    height: 20px;
  }

  .css-1pqm26d-MuiAvatar-img {
    width: 70%;
    height: 70%;
  }

  .home-heading-para {
    font-size: 24px;
  }

  .img-heading-home {
    position: relative;
    bottom: 14px;
  }

  .home-heading-para-ans {
    font-size: 18px;
  }


  .testimonial-card-heading-text {
    font-size: 16px;
  }

  .pagination button {
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 40%;
    margin: 0 2px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .video-react {
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .image-slider-mobile {
    display: none;
  }

  .card-view-profile-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .card-view-profile-container-mobile {
    display: none;
  }

  .testimonial-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background: white;
    width: 230px;
    height: 480px;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .video-react .video-react-video {
    border-radius: 10px;
  }

  .video-close-icon {
    top: 4%;
    left: 92%;
  }

  .text-btn-content-mobile {
    display: none;
  }

  .collapsible {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .collapsible-button.open {
    width: 100%;
  }

  .collapsible-button.closed {
    width: 100%;
  }

  .collapsible-content {
    width: 100%;
  }

  .home-heading-para {
    font-size: 24px;
  }

  .home-heading-para-ans {
    font-size: 18px;
  }

}

@media only screen and (max-width: 767px) and (min-width: 280px) {

  .home-first-pages-mobile {
    width: 100%;
    display: flex;
    background-color: #e0ffff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }

  .home-mobile-first-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
  }

  .content-btn-container {
    position: relative;
    left: 0px !important;
    padding-bottom: 20px;
  }

  .home-mobile-first-img--align {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  .home-mobile-second-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .aboutUs-text-heading {
    font-size: 16px;
  }

  .aboutUs-contant-home-page {
    width: 100%;
  }

  .home-first-pages {
    display: none;
  }

  .home-heading-para {
    font-size: 16px;
  }

  .home-heading-para-ans {
    font-size: 14px;
  }

  .image-slider-mobile {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .image-slider {
    display: none;
  }

  .arrow-icon {
    cursor: pointer;
    background-color: #008080;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 15px;
    color: #ffffff;
    transition: background-color 0.3s ease;
  }

  .card-view-profile-container-mobile {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .card-view-profile-container {
    display: none;
  }

  .btn-aboutUs-align {
    width: 100%;
  }

  .btn-FAQ-align {
    width: 100%;
  }

  .first-heading-aboutUS-para {
    width: 100%;
  }

  .heading-aboutUS-paras-text {
    width: 100%;
  }

  .heading-aboutUS-paras-text {
    width: 100%;
  }

  .second-heading-aboutUS-para {
    width: 100%;
  }

  .video-react {
    margin-bottom: 10px;
    margin-left: 5px;
    border-radius: 10px !important;
    background-color: black !important;
  }

  .video-close-icon {
    position: absolute;
    top: 3%;
    left: 85%;
  }

  .text-btn-content-mobile {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    padding-left: "16px";
    display: flex;
    padding-bottom: "16px";
    border-radius: "8px";
    position: relative;
  }

  .video-container {
    display: none;
  }

  .aboutUs-text-heading {
    font-size: 16px;
  }

  .card-view-profile-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .testimonial-card {
    width: 250px;
    height: 340px;
    margin-bottom: 20px;
  }

  .aboutUs-text-para-heading {
    font-size: 14px;
  }

  .aboutUs-text-paras {
    font-size: 12px;
    line-height: 14px;
  }

  .img-childern-one-homePage {
    width: 100px;
    height: 150px;
  }

  .img-children-homePage-two {
    width: 65px;
    height: 100px;
  }

  .circle-line-img-view {
    position: relative;
    top: -5px;
  }

  .img-home-page-circle {
    width: 30px;
    height: 40px;
  }

  .home-page-text {
    font-size: 16px;
  }

  .home-page-para-text {
    font-size: 14px;
  }

  .custom-button {
    width: 130px;
    height: 40px;
    font-size: 12px;
  }

  .aboutUs-img-first {
    width: 300px;
    height: 280px;
  }

  .content-aboutUs-text-container {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .home-works-container {
    flex-direction: column;
  }

  .sub-works-container {
    flex-direction: column-reverse;
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
  }

  .about-img {
    width: 260px;
    height: 400px;
  }

  .work-heading-label {
    font-size: 16px;
  }

  .page-book-img {
    width: 50px;
    height: 50px;
  }

  .para-works-page-text {
    color: #000606;
    font-size: 14px;
  }

  .para-step {
    font-size: 14px;
  }

  .step-tracker {
    margin-top: 0px;
  }

  .custom-btn-align-works {
    margin-top: 10px;
  }

  .heading-books-Page {
    font-size: 16px;
  }

  .subHeading-books-Page {
    font-size: 20px;
  }

  .book-para-text {
    font-size: 14px;
    line-height: 18px;
  }

  .books-align-content-text-card {
    width: 100%;
    margin-top: 0px;
  }

  .img-faq-pages {
    width: 250px;
    height: 270px;
  }

  .faq-heading-text {
    font-size: 18px;
  }

  .faq-form-container {
    width: 100%;
  }

  .faq-heading-para {
    font-size: 14px;
  }

  .faq-content-container {
    padding-top: 100px;
    width: 100%;
  }

  .collapsible-text-heading {
    font-size: 12px;
    text-align: left;
  }

  .book-image {
    width: 250px;
    height: 340px;
    margin-right: 0;
  }

  .story-heading-color {
    font-size: 20px;
  }

  .story-heading--para {
    font-size: 14px;
  }

  .home-story-container {
    height: 150px;
  }

  .testimonial-heading-text {
    font-size: 18px;
  }

  .testimonial-para-text {
    font-size: 20px;
  }

  .testimonial-card-para-text {
    font-size: 12px;
    line-height: 16px;
  }

  .css-1pqm26d-MuiAvatar-img {
    width: 60%;
    height: 60%;
  }

  .sub-aboutUs-container {
    flex-direction: column;
    width: 100%;
  }

  .aboutUS-view-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub-faq-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .collapsible-button.closed {
    width: 100%;
  }

  .collapsible-button.open {
    width: 100%;
  }

  .collapsible-content {
    padding: 20px;
    width: 100%;
    background-color: #ffffff;
  }

  .collapsible {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1025px) {
  .video-close-icon {
    position: absolute;
    top: 4%;
    left: 69%;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1301px) {
  .video-close-icon {
    position: absolute;
    top: 4%;
    left: 60%;
  }
}