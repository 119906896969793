@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', 'Macondo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins', 'Merriweather', 'Inter', "Josefin Sans"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Poppins',
    'Merriweather', 'Inter', 'Montserrat', 'Roboto',
    monospace;
}

.cp-chat.cp-minimized {
  box-shadow: none;
  height: 60px;
  left: 92% !important;
  bottom: 2% !important;
}

@media only screen and (max-width: 767px) and (min-width: 280px) {
  .cp-chat.cp-minimized {
    box-shadow: none;
    height: 60px;
    left: 80% !important;
    bottom: 2% !important;
  }
}