* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 13%;
  position: fixed;
  z-index: 1;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.jeurney-logo {
  padding-left: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 15%;
  padding-top: 10px;
}

.logo-header-img {
  width: 80px;
  height: 50px;
}

.top-navbar {
  height: 100%;
  width: 75%;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.nav-bar {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 20px;
}

.container-demo-pdf-button {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 20%;
  padding-bottom: 10px;
}

.demo-pdf-button {
  background-color: #008080;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.demo-pdf-button:hover {
  background-color: #006666;
}

.nav-menu-item {
  height: 100%;
  width: 85%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
}

.link {
  text-decoration: none;
  color: #000606;
  font-size: 18px;
  font-family: "Roboto";
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
}

.link:hover {
  color: #008080;
}

@media (max-width: 768px) {
  .nav-menu-item {
    display: none;
  }

  .logo-header-img {
    width: 130px;
    padding-top: 15px;
  }

  .dtds-navbar-toggle {
    display: block;
    margin-right: 1%;
  }

  .nav-bar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    font-size: 20px;
  }

  .jeurney-logo {
    width: 30%;
  }

  .container-demo-pdf-button {
    display: none;
  }

  .demo-pdf-button {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .nav-menu-item {
    display: none;
  }

  .logo-header-img {
    margin-bottom: 10px;
    height: 55px;
    width: 90px;
  }

  .dtds-navbar-toggle {
    display: block;
    margin-right: 1%;
  }

  .nav-bar {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    font-size: 20px;
  }

  .jeurney-logo {
    width: 30%;
  }

  .container-demo-pdf-button {
    display: none;
  }

  .demo-pdf-button {
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1439px) {
  .top-navbar {
    width: 80%;
  }

  .jeurney-logo {
    width: 13%;
  }

  .nav-menu-item {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .nav-menu-item {
    display: none;
  }

  .dtds-navbar-toggle {
    display: block;
    margin-right: 1%;
  }

  .container-demo-pdf-button {
    display: none;
  }
}

@media (min-width: 1024px) {
  .nav-menu-item {
    height: 100%;
    width: 78%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }

  .logo-header-img {
    width: 150px;
  }

  .dtds-navbar-toggle {
    display: none;
  }

  .container-demo-pdf-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.active-nav:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  border-radius: 4px;
  margin-top: 5%;
  background: transparent;
  transition: width 0.6s ease, background-color 0.6s ease;
}

.clicked-nav:after {
  width: 100%;
  color: #008080;
}

.clicked-nav {
  color: #008080;
}