* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.main {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: white;
}

.head-component {
	height: 8%;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.body-component {
	height: 92%;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: scroll;
	margin-top: 25px;
}

@media only screen and (max-width: 1024px) {
	.head-component {
		height: 10%;
	}

	.body-component {
		height: 90%;
		margin-top: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.head-component {
		height: 15%;
	}

	.body-component {
		margin-top: 25px;
		height: 85%;
	}
}